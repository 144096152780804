






import { Component, Vue } from 'nuxt-property-decorator'
import LayoutPing from '@Core/layouts/ping.vue'

@Component({
  components: {
    LayoutPing,
  },
})
export default class NuxtPingLayout extends Vue {}
