






import { Component, Vue } from 'nuxt-property-decorator'
import RedeemLayout from '@Gifts/layouts/RedeemLayout.vue'
import AlertNotification from '@UI/components/notifications/AlertNotification.vue'
import Back from '@UI/components/buttons/Back.vue'

@Component({
  components: {
    Back,
    RedeemLayout,
    AlertNotification,
  },
})
export default class NuxtRedeemLayout extends Vue {}
