






import { Component, Vue } from 'nuxt-property-decorator'
import EventLayout from '@Events/layout/EventLayout.vue'

@Component({
  components: {
    EventLayout,
  },
})
export default class NuxtEventLayout extends Vue {}
