export default async function ({ store, route, redirect }) {
  if (!process.server) {
    await store.dispatch('session/setRedirect', route.path)

    if (!store.getters['customer/customer']) {
      try {
        await store.dispatch('customer/getActiveCustomer')
      } catch (err_) {
        console.warn('Session expired')
      }
    }

    // If the user is not authenticated
    if (!store.getters['customer/isLoggedIn']) {
      return redirect('/sign-in')
    }
  }
}
