






import { Component, Vue } from 'nuxt-property-decorator'
import BasketLayout from '@Basket/layout/BasketLayout.vue'

@Component({
  components: {
    BasketLayout,
  },
})
export default class NuxtBasketLayout extends Vue {}
