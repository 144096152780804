




























import { Component, Vue } from 'nuxt-property-decorator'
import AccountLayout from '@Account/layouts/account.vue'
import ResizeObserver from '@UI/components/resize-oberserver/ResizeObserver.vue'
import HeaderPart from '@Core/components/parts/header/HeaderPart.vue'
import FooterPart from '@Core/components/parts/footer/FooterPart.vue'
import VerticalNavigation from '@UI/components/navigation/VerticalNavigation.vue'
import AccountMenu from '@Account/helpers/account-menu'
import AccountHeader from '@Account/layouts/header.vue'
import AlertNotification from '@UI/components/notifications/AlertNotification.vue'

@Component({
  components: {
    AccountLayout,
    ResizeObserver,
    HeaderPart,
    FooterPart,
    VerticalNavigation,
    AccountHeader,
    AlertNotification,
  },
})
export default class NuxtAccountLayout extends Vue {
  public items = AccountMenu
}
