






import { Component, Vue } from 'nuxt-property-decorator'
import AuthLayout from '@Auth/layouts/AuthLayout.vue'
import AlertNotification from '@UI/components/notifications/AlertNotification.vue'
import Back from '@UI/components/buttons/Back.vue'

@Component({
  components: {
    Back,
    AuthLayout,
    AlertNotification,
  },
})
export default class NuxtAuthLayout extends Vue {}
