/**
 * If the basket is already complete, but user somehow ended up away from the success page
 * redirect to success page with orderID
 */
export default async function ({ store, redirect }) {
  const basketComplete = await store.dispatch('basket/getBasketComplete')

  if (basketComplete) {
    const basket = await store.dispatch('basket/getBasket', {
      fetchPolicy: 'cache-first',
      hasSeatingConfig: true,
    })
    return redirect(
      '/payment/success/?basketMid=true&orderId=' + basket.order_ref
    )
  }
}
