






import { Component, Vue } from 'nuxt-property-decorator'
import LayoutTicketing from '@Tickets/layouts/ticketing.vue'

@Component({
  components: {
    LayoutTicketing,
  },
})
export default class NuxtTicketingLayout extends Vue {}
