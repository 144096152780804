









import { Component, Vue } from 'nuxt-property-decorator'
import HomeLayout from '@Marketing/layouts/home.vue'
import AlertNotification from '@UI/components/notifications/AlertNotification.vue'

@Component({
  components: {
    HomeLayout,
    AlertNotification,
  },
})
export default class NuxtHomeLayout extends Vue {}
