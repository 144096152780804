









import { Component, Vue } from 'nuxt-property-decorator'
import DefaultLayout from '@Core/layouts/default.vue'
import AlertNotification from '@UI/components/notifications/AlertNotification.vue'

@Component({
  components: {
    DefaultLayout,
    AlertNotification,
  },
})
export default class NuxtDefaultLayout extends Vue {}
