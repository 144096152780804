import modulesMiddlewares from '~/.nuxt/middleware.modules'

/**
 * Override the default nuxt middleware mechanism to
 * work with the modular framework
 * see https://vuems.ergonode.com/middleware
 */
export default (ctx) => {
  modulesMiddlewares(ctx)
}
