






import { Component, Vue } from 'nuxt-property-decorator'
import AnnualReviewLayout from '@Marketing/layouts/AnnualReview.vue'
import AlertNotification from '@UI/components/notifications/AlertNotification.vue'

@Component({
  components: {
    AnnualReviewLayout,
    AlertNotification,
  },
})
export default class NuxtHomeLayout extends Vue {}
