



import { Component, Vue } from 'nuxt-property-decorator'
import Error from '@Core/components/errors/Error.vue'

@Component({
  components: {
    Error,
  },
})
export default class ErrorLayout extends Vue {}
